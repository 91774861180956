@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar{
    top: 60px;
    width: 20%;
    background: #000614;
    min-height: 66vh;
    border-radius: 0 0 10px 0;
    position:fixed;
}

.Dropdown{
    display: none;
}

.sidebar-sel{
    background: #59219D;
    border-radius: 17px;
}

.coin{
    padding: 0.5rem 1rem;
    font-weight: bold;
    cursor: pointer;
    margin: 1rem;
    font-size: 13px;
}
@media screen and (max-width:1200px) and (min-width:270px) {
    .sidebar{
        display: none;
    }   
    .Dropdown{

        display: inline-block;
        margin-top: 80px;
        width: 100%;
        padding: 0 10px;
        display: inline-block;
        margin-bottom: 5px;
    }
    .Dropdown button{
        background: #59219D;
        width: 100%;
        height: 40px;
        /* margin: 0px; */
        border-radius: 12px;
        text-align: left;
    }
    .dropdown-menu{
        width: 100%;
        background: #51307a;
        color: white;
        border-radius: 10px;
    }
    .dropdown-item{
        color: white;
    }
    .comingsoondropdown{
        position: absolute;
        font-size: 12px;
        right: 10px;
        background-color: #4d1f84;
        font-weight: bold;
        text-align: center;
        color: whitesmoke;
        border-radius:6px;
        padding: 2px 8px;
    }
    .btn:active, .btn:focus, .btn:hover,.btn,.btn-primary {
        background-color: #59219D !important;
        border-color: #59219D !important;
        box-shadow: none;
    }
    .dropdown-toggle::after {
        display: none !important;
    }
    
    
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.tradeboxhead{
    
    width:100%;
    font-weight:800;
    font-size:20px;
    font-family: 'Inter', sans-serif;
}
.tradebox_main{
    min-width: 50vh;
    max-width: 50vh;
	height: auto;
}
.css-eg0mwd-MuiSlider-thumb{
    width: 12px !important;
    height: 12px !important;
    background-color:#A9A9A9!important;
}
.css-fb7w07-MuiSlider-root{
    color: #A9A9A9 !important;
}
.tradebox_leverage{

    position: relative;
    background: #30313D;
border-radius: 6px;
min-width: 100% !important;
min-height: 90px;
top: 10px;
left: -10px;
padding: 10px;
margin: auto;
background-color: #59219D;
color: #A9A9A9;
}
.tradebox_amount{
    position: relative;
    width: 100% !important;
}
.tradebox_levslider{
    position: relative;
    left: 13px;
    font-size: 13px !important;
}
.tradebox_inputicon{
    position: absolute;
    top: 20%;
font-size: 13px;
font-weight: 600;

    z-index: 2;
}
.tradebox{
    position: relative;
    width: 100% !important;
height: 40px;
color: white;
padding-right: 10px;
left: -10px;
border: 0;
font-size: 14px;
text-align: end;
background-color: #59219D;

border-radius: 6px;
}
.tradebox_table1{
    position: relative;
    left: -10px;
    margin-top: 20px!important;
    width: 100% !important;
}
.tradebox_table1 tr{
    font-size: 12px !important;
    font-weight:500;
}
.tradebox_table1 td{
    padding: 6px 0;
}
.tradebox_button{
    position: relative;
    left: -10px;

}
.amount_percent_btn{
    margin:1px 3px;
    padding:2px;
    color:white;
    background:none ;
    border:0.5px solid #59219d;
    border-radius:4px;
    font-size: 10px;
    width: 35px;
}
.amount_percent_btn:hover{
    background:#59219d ;
    color:white !important;

}
@media screen and (max-width:768px)and (min-width:540px){
    .tradebox_main{
        min-width: 60vh;
        max-width: 60vh;

    }
}
@media screen and (max-width:540px)and (min-width:410px){
    .tradebox_main{
        min-width: 80%;
        max-width: 45vh;
        min-height: 500px !important;

    }
    .css-h206zr{
        padding: 15px !important;
        min-height: 500px !important;

    }
    .modal-content{
        padding: 0 !important;
        width: 85% !important;
    }
    .modal-body hr{
        top: 0px !important;
        margin: 0 !important;
    }
    
}
@media screen and (max-width:409px)and (min-width:240px){
    .tradebox_main{
        min-width: 80%;
        max-width: 45vh;
    }
    .css-h206zr{
        padding: 15px !important;
        min-height: 500px !important;


    }
    .modal-title{
        font-size: 18px !important;
    }
    .modal-content{
        padding: 0 !important;
        width: 85% !important;
    }
    .modal-body hr{
        top: 0px !important;
        margin: 0 !important;
    }
}


/* popovers css */
.btn-close{
    display: none;
}

.modal-content{
    padding: 15px;
    margin: auto;
    width: 75%;
    height: auto;
    background-color: #141724;
}
.marginbodydiv{
    display: flex;
}
.marginbodydiv p{
    flex-basis: 50%;
}
.marginbodydiv p:last-child{
    text-align: right;
}
.model-body{
    border: none !important;
}
.LeaderboardMain{
    position: fixed;
    left: 0;
    width: 100% !important;
    min-height: 100vh !important;
    z-index: 3 !important;
    top: 60px;
    background-color: #0e0f18 ;
    padding-top: 5vh;
    overflow-y: scroll ;

}


.Leaderboardcontainer{
    width: 70%;
    margin: 10px auto;
}

.Leaderboardcontainer input{
  padding-left: 40px;
    width: 100%;
    background-color: #281e3d;
    border-radius: 10px;
    border: 1px solid #59219d;
    height: 35px;
    margin-bottom: 20px;

}

input, select, textarea{
  color: whitesmoke;
}
.Leaderboardcontainer h2{
    font-size: 18px;
    height: 50px;
    color: whitesmoke;
    width: 100%;
    border: 0.5px solid #59219d;
    background: #59219d;
    font-weight: bold;
    border-bottom: none;
    margin-bottom: 0;
    border-radius: 10px 10px 0px 0px;
    text-align: left;
    padding-top: 14px;
    padding-left: 20px;
}
.Leaderboardcontainer table{
  width: 100%;
    border: 0.5px solid #59219d;
    margin-bottom: 0;
    background: #281e3d ;
} 
.Leaderboardcontainer th{
      height: 45px;
      text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: whitesmoke;

}
.Leaderboardcontainer td{
  height: 35px;
  text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: rgb(232, 204, 204) ;
}
.Leaderboardcontainer tr{
 border-style: none !important;
 border-bottom : 0.2px solid #59219d !important;
}

tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: none;
    border-width: 0;
}
.Leaderboardcontainer .table_div{
  min-height: 310px !important;
  border: 0.5px solid #59219d;
  margin-bottom: 0;
  background: #281e3d ;
}
.table > :not(caption) > * > * {
    padding: .5rem .5rem;
    border-bottom-width: 1px;
  }
  .pagination{
    width: 100%;
    border-radius:  0 0px 10px 10px;
  }
  .middlepagination{
    width: 100%;
    text-align: center;
    font-size: 12px;
  }


  .page-item :hover{
  
    background: #59219d !important;
    color: whitesmoke !important;
    border: none !important;
  }
  .page-item {
    color: whitesmoke !important;
    border: none !important;
    box-shadow: none;
  }
  .react-bootstrap-table-pagination-list{
    width: 100%;
  }
  @media screen and (min-width:1800px) and (max-width:4000px){
    .Leaderboardcontainer td{
      height: 50px;
      text-align: center;
        font-size: 13px;
        font-weight: 500;
        color: rgb(232, 204, 204) ;
    }
    .Leaderboardcontainer th{
      height: 60px;
      text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: whitesmoke;

}
.Leaderboardcontainer .table_div{
  min-height: 510px !important;
  border: 0.5px solid #59219d;
  margin-bottom: 0;
}
  }

  @media screen and (min-width:270px) and (max-width:796px){
    .Leaderboardcontainer{
      box-sizing: border-box;
      width: 90%;
      margin: 10px auto;
  }
  .table_div{
    overflow-y: scroll;

  }
  .table_div table{
    width: 450px;
  }
  .leaderboard_head{
    font-size: 10px !important;
  }
  .leaderboard_td{
    font-size: 10px !important;
  }
  }
