@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;800&display=swap');
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.tradeboxhead{
    
    width:100%;
    font-weight:800;
    font-size:20px;
    font-family: 'Inter', sans-serif;
}
.tradebox_main{
    min-width: 50vh;
    max-width: 50vh;
	height: auto;
}
.css-eg0mwd-MuiSlider-thumb{
    width: 12px !important;
    height: 12px !important;
    background-color:#A9A9A9!important;
}
.css-fb7w07-MuiSlider-root{
    color: #A9A9A9 !important;
}
.tradebox_leverage{

    position: relative;
    background: #30313D;
border-radius: 6px;
min-width: 100% !important;
min-height: 90px;
top: 10px;
left: -10px;
padding: 10px;
margin: auto;
background-color: #59219D;
color: #A9A9A9;
}
.tradebox_amount{
    position: relative;
    width: 100% !important;
}
.tradebox_levslider{
    position: relative;
    left: 13px;
    font-size: 13px !important;
}
.tradebox_inputicon{
    position: absolute;
    top: 20%;
font-size: 13px;
font-weight: 600;

    z-index: 2;
}
.tradebox{
    position: relative;
    width: 100% !important;
height: 40px;
color: white;
padding-right: 10px;
left: -10px;
border: 0;
font-size: 14px;
text-align: end;
background-color: #59219D;

border-radius: 6px;
}
.tradebox_table1{
    position: relative;
    left: -10px;
    margin-top: 20px!important;
    width: 100% !important;
}
.tradebox_table1 tr{
    font-size: 12px !important;
    font-weight:500;
}
.tradebox_table1 td{
    padding: 6px 0;
}
.tradebox_button{
    position: relative;
    left: -10px;

}
.amount_percent_btn{
    margin:1px 3px;
    padding:2px;
    color:white;
    background:none ;
    border:0.5px solid #59219d;
    border-radius:4px;
    font-size: 10px;
    width: 35px;
}
.amount_percent_btn:hover{
    background:#59219d ;
    color:white !important;

}
@media screen and (max-width:768px)and (min-width:540px){
    .tradebox_main{
        min-width: 60vh;
        max-width: 60vh;

    }
}
@media screen and (max-width:540px)and (min-width:410px){
    .tradebox_main{
        min-width: 80%;
        max-width: 45vh;
        min-height: 500px !important;

    }
    .css-h206zr{
        padding: 15px !important;
        min-height: 500px !important;

    }
    .modal-content{
        padding: 0 !important;
        width: 85% !important;
    }
    .modal-body hr{
        top: 0px !important;
        margin: 0 !important;
    }
    
}
@media screen and (max-width:409px)and (min-width:240px){
    .tradebox_main{
        min-width: 80%;
        max-width: 45vh;
    }
    .css-h206zr{
        padding: 15px !important;
        min-height: 500px !important;


    }
    .modal-title{
        font-size: 18px !important;
    }
    .modal-content{
        padding: 0 !important;
        width: 85% !important;
    }
    .modal-body hr{
        top: 0px !important;
        margin: 0 !important;
    }
}


/* popovers css */
.btn-close{
    display: none;
}

.modal-content{
    padding: 15px;
    margin: auto;
    width: 75%;
    height: auto;
    background-color: #141724;
}
.marginbodydiv{
    display: flex;
}
.marginbodydiv p{
    flex-basis: 50%;
}
.marginbodydiv p:last-child{
    text-align: right;
}
.model-body{
    border: none !important;
}