@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;800&display=swap');
.sidebar{
    top: 60px;
    width: 20%;
    background: #000614;
    min-height: 66vh;
    border-radius: 0 0 10px 0;
    position:fixed;
}

.Dropdown{
    display: none;
}

.sidebar-sel{
    background: #59219D;
    border-radius: 17px;
}

.coin{
    padding: 0.5rem 1rem;
    font-weight: bold;
    cursor: pointer;
    margin: 1rem;
    font-size: 13px;
}
@media screen and (max-width:1200px) and (min-width:270px) {
    .sidebar{
        display: none;
    }   
    .Dropdown{

        display: inline-block;
        margin-top: 80px;
        width: 100%;
        padding: 0 10px;
        display: inline-block;
        margin-bottom: 5px;
    }
    .Dropdown button{
        background: #59219D;
        width: 100%;
        height: 40px;
        /* margin: 0px; */
        border-radius: 12px;
        text-align: left;
    }
    .dropdown-menu{
        width: 100%;
        background: #51307a;
        color: white;
        border-radius: 10px;
    }
    .dropdown-item{
        color: white;
    }
    .comingsoondropdown{
        position: absolute;
        font-size: 12px;
        right: 10px;
        background-color: #4d1f84;
        font-weight: bold;
        text-align: center;
        color: whitesmoke;
        border-radius:6px;
        padding: 2px 8px;
    }
    .btn:active, .btn:focus, .btn:hover,.btn,.btn-primary {
        background-color: #59219D !important;
        border-color: #59219D !important;
        box-shadow: none;
    }
    .dropdown-toggle::after {
        display: none !important;
    }
    
    
}